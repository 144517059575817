import { Component, OnInit } from '@angular/core';
import { AuthGuardService } from '../guards/auth-guard.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private authGuardService: AuthGuardService) { }

  loggedIn = false;
  showButton = true;

  ngOnInit() {


    setInterval(() => {
      //fix this workaround using an observable
      if (window.location.href.includes('/ad-feedback')) {
        // console.log(window.location.href)
        this.showButton = false;
      } else {
        this.loggedIn = this.authGuardService.isLoggedIn();
        this.showButton = true;
      }
    }, 1000);
  }

  signIn() {
    return this.authGuardService.signInWithGoogle();
  }

  signOut() {
    return this.authGuardService.signOut();
  }


}
