import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Feedback } from '../model/feedback';
import { SocialAuthService, SocialUser, GoogleLoginProvider } from "angularx-social-login";
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

interface Permissions {
  authorized: boolean,
  feedBackSubmitted: boolean
}

@Injectable({
  providedIn: 'root'
})

export class AuthGuardService implements CanActivate {
  user: SocialUser;
  loggedIn: boolean;

  constructor(private authService: SocialAuthService, private http: HttpClient, private router: Router) { }

  isLoggedIn() {
    return this.loggedIn;
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);

    this.authService.authState.subscribe((user) => {
      this.user = user;
      this.http.post('/api/auth', { token: this.user }).pipe(
        map((res: Permissions) => {
          if (res.authorized) {
            this.router.navigate(['home']);
            this.loggedIn = true;

            // Save to localStorage
            const item = {
              user: this.user,
              expiry: new Date().getTime() + 43000000
            }
            localStorage.setItem("fmgportal", JSON.stringify(item))

          }
        }),
        tap(res => console.log('auth-verify: 200', res)),
        catchError(this.handleError('auth-verify', ''))
      ).subscribe();

    });

  }

  signOut(): void {
    // this.authService.signOut();
    localStorage.removeItem("fmgportal");
    this.loggedIn = false;
    this.router.navigate(['/welcome']);

    this.http.get('/logout').subscribe();

  }

  feedBack(data: any) {
    this.http.post('/feedback', { data }).pipe(
      map((res: Permissions) => {
        console.log('feedback-submitted: map: ', res.feedBackSubmitted);
        if (res.feedBackSubmitted) {
          this.router.navigate(['home']);
        }
      }),
      tap(res => console.log('feedback-submitted: 200')),
      catchError(this.handleError('feedback-submitted', ''))
    ).subscribe();
  }




  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.loggedIn)
      return true;

    const lastSession = localStorage.getItem("fmgportal");

    if (!lastSession) {
      console.log("No previous session found.");
      this.router.navigate(['/welcome']);
      return false;
    }

    const sessionInfo = JSON.parse(lastSession)
    console.log("Previous session", sessionInfo);
    if (new Date().getTime() > sessionInfo.expiry) {
      console.log("Expired!", new Date().getTime() - sessionInfo.expiry);
      this.router.navigate(['/welcome']);
      return false;
    } else {
      this.loggedIn = true;
      return true;
    }

  }



  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


}
