import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { BaseComponent } from './base/base.component';
import { PrivacyComponent } from './privacy/privacy.component';

// import { ApiService } from './api.service';


import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider } from 'angularx-social-login';
import { ContactComponent } from './contact/contact.component';
import { ApiService } from './api.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    BaseComponent,
    PrivacyComponent,
    ContactComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    SocialLoginModule,
    HttpClientModule
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig', 
      useValue: {
        autoLogin: false,
        providers: [{
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
	     '500623399602-u29a4ek800s2aitjlakrojed8n77hh66.apps.googleusercontent.com',
	    // '222226933329-hrruv11rpjd3r2sbkukhcbtkabakgm9d.apps.googleusercontent.com',//secret BDfj8gRDcwndY0sT2buGQY9M
            ),
          },
        ]
      } as SocialAuthServiceConfig
    }, ApiService,
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
