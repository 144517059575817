import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss', './base.component.mobile.scss']
})
export class BaseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

 
}
