import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { Component, OnChanges, OnInit, DoCheck } from '@angular/core';
// import { Component, OnInit} from '@angular/core';

// import { Params } from '@angular/router';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
// export class AppComponent {
  title = 'client';
  // route: any;
  params: Params;



 
  constructor(private router : Router) {} // make variable private so that it would be accessible through out the component

ngOnInit() {

  }
  
 
  
}
