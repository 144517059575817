import { Component, OnInit } from '@angular/core';
import { Feedback } from '../model/feedback';
import { AuthGuardService } from '../guards/auth-guard.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss', './contact.component.mobile.scss']
})
export class ContactComponent implements OnInit {
  //name of parameters to fetch
  // public postJsonValue: any;
  banner_id: string;
  device: string;
  country: string;
  zone_id: string;
  feedbackrequired: boolean;
  mobile: string;
  email: string;
  suggestion: string;

  constructor(private router: Router, private authGuardService: AuthGuardService, private http: HttpClient,
    private route: ActivatedRoute,
    private apiService: ApiService) { }

  ngOnInit(): void {
    this.feedbackrequired = false
    this.route.queryParams
      .subscribe(params => {
        this.zone_id = params.zoneid
        this.banner_id = params.bannerid
        this.device = params.devicetype
        this.country = params.country
      }
      );
  }
  //  }
  // public postMethod() {
  //   this.route.queryParams.subscribe(queryParam => {
  //     this.bannerid = queryParam.bannerid;
  //     this.devicetype = queryParam.devicetype;
  //     this.country = queryParam.country;
  //     this.zoneid = queryParam.zoneid;
  //   })
  //   let body = {
  //     title: this.bannerid,
  //     body: this.devicetype,
  //     userId: this.country,
  //     cazone: this.zoneid,
  //   };

  //   this.http.post('http://localhost:4201/feedback', body).subscribe((data3) => {
  //     // console.log(res);
  //     // this.postJsonValue = data3;
  //     // this.apiService.onSubmit(data3);
  //   });

  //   // this.http.post('https://jsonplaceholder.typicode.com/posts',body).subscribe((data3) => {
  //   //   console.log(data3);

  //   //   // this.authGuardService.feedBack(this.postJsonValue);
  //   // });
  // }

  feedModel = new Feedback('', '', '', '', '');
  onSubmit(data): boolean {
    data.zone_id = this.zone_id
    data.banner_id = this.banner_id
    data.device = this.device
    data.country = this.country
    if (data && data.feedback) {
      this.apiService.onSubmit(data);
    } else {
      this.feedbackrequired = true
      return false
    }
  }



}
