
export class Feedback {
    route: any;
    constructor(
        public gridRadios3: string,
        public Describetextarea: string,
        public email: string,
        public contactUser: string,
        public countrydata: string,


    ) { 
        
       
     
}
}
