import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { BaseComponent } from './base/base.component';
import { PrivacyComponent } from './privacy/privacy.component'
import { ContactComponent } from './contact/contact.component';
import { AuthGuardService } from './guards/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'welcome', component: BaseComponent },
  { path: 'privacy-policy', component: PrivacyComponent },
  { path: 'ad-feedback', component: ContactComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuardService] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
