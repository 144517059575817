import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient, private router: Router) { }

  onSubmit(data) {
    this.http.post('https://contextads.media/feedback', data).subscribe(res => {
      this.router.navigate(['home']);
    })
  }

  // queryParam(data3) {
  //   this.http.post('https://jsonplaceholder.typicode.com/posts', data3).subscribe(res => {
  //     // console.log(res);
  //   })
  // }
}
