import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  // public obj: AuthorModel = { bannerid: 'stupid' , device: 'tablet' ,country: 'amerika' }

  constructor() { }

  ngOnInit() {
  }

  selectedCategory = "All";

  categories = ["All", "ML", "ADS", "Demo"];

  products = [{
      name: 'DMP',
      alternateName: 'Inventory and Audience Analytics',
      img: '/assets/dmp.svg',
      category: "Demo",
      url: 'https://audience.contextads.media'
    },
    {
      name: 'Contextual Inventory',
      alternateName: 'CEngine Inventory Dashboard',
      img: '/assets/cengine.svg',
      category: "ADS",
      url: 'https://inventory.contextads.media'
    },
    {
      name: 'Contextual Inventory (Sales)',
      alternateName: 'CEngine Inventory Dashboard',
      img: '/assets/cengine.svg',
      category: "ADS",
      url: 'https://inventory.contextads.media/?version=sales'
    },
    {
      name: 'CEngine Demo',
      alternateName: 'Article safety classification',
      img: '/assets/cengine.svg',
      category: "Demo",
      url: 'https://cengine.contextads.media'
    },
    // {
    //   name: 'Advanced CEngine Demo',
    //   alternateName: 'In-depth article analysis',
    //   img: '/assets/cengine.svg',
    //   category: "ML",
    //   url: 'https://cengine2.contextads.media'
    // },
    {
      name: 'Keyword Recommender Demo',
      alternateName: 'Predictive keywords',
      img: '/assets/cengine.svg',
      category: "ML",
      url: 'https://keyword-recommender.contextads.media'
    },
    // {
    //   name: 'CEngine Brand Safety Demo',
    //   alternateName: 'Article safety and Image classification',
    //   img: '/assets/cengine.svg',
    //   category: "Demo",
    //   url: 'https://cengine-image-demo.contextads.media'
    // }
  ];
    
  filterCheck(product) {
    if (this.selectedCategory == "All")
      return true;
    return this.selectedCategory == product.category;
  }

  launchApp(url) {
    window.location.href = url;
  }
}
